import React from 'react';
import { Flex, Text, Image, Icon, Link } from '@chakra-ui/react';
// import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { IoLogoLinkedin } from 'react-icons/io5';
import Me from '../assets/me.jpg';

function Home() {
  return (
    <>
      <Flex
        h={'50vh'}
        bg="gray.200"
        justify={'center'}
        align={'center'}
        flexDir={'column'}
        pos={'relative'}
      >
        <Image
          src={Me}
          w={'150px'}
          h={'150px'}
          borderRadius={'full'}
          objectFit={'cover'}
          objectPosition={'top'}
          pos={'absolute'}
          top={-75}
          borderWidth={10}
          borderStyle={'solid'}
          borderColor={'gray.200'}
        />
        <Flex
          maxW={'7xl'}
          textAlign={'center'}
          fontSize={'xl'}
          mb={10}
          flexDir={'column'}
          align={'center'}
        >
          <Text my={5} fontSize={{ base: 'sm', md: 'md' }} maxW={'2xl'}>
            I'm an experienced software engineer with team leadership
            experience. I have a background in full-stack web development,
            digital marketing, and ecommerce. I am always looking for
            opportunities to learn and grow. I'm currently looking for a new
            role, so if you think I'd be a good fit for your team, please don't
            hesitate to reach out.
          </Text>
          <Link href="https://www.linkedin.com/in/ted-d-nguyen/" isExternal>
            <Icon as={IoLogoLinkedin} w={10} h={10} color={'linkedin.800'} />
          </Link>
        </Flex>
      </Flex>
    </>
  );
}
export default Home;
