import React from 'react';

import {
  Image,
  keyframes,
  usePrefersReducedMotion,
  Flex,
  Container,
  Link,
} from '@chakra-ui/react';
import logo from '../../moon.jpg';
const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;
const Spinner = props => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion
    ? undefined
    : `${spin} infinite 24s linear`;
  return (
    <Image
      animation={animation}
      src={logo}
      {...props}
      pos={'absolute'}
      maxW={'xs'}
      top={0}
    />
  );
};
function index(props) {
  return (
    <Flex
      bg={'black'}
      h={'50vh'}
      align={'center'}
      justify={'flex-start'}
      // w={'100%'}
      flexDir={'column'}
      pos={'relative'}
    >
      <Flex w={'full'} bg="whiteAlpha.50" py={2} color={'white'} zIndex={1000}>
        <Container maxW={'4xl'}>
          <Flex w={'full'} justifyContent={'space-between'} align={'center'}>
            <Link
              textTransform={'uppercase'}
              fontWeight={900}
              fontSize={'5xl'}
              color={'red.500'}
              flexGrow={1}
              href="/"
              _hover={{
                textDecoration: 'none',
                color: 'white',
              }}
            >
              Ted
            </Link>
            <Flex>
              <Link
                fontWeight={500}
                textTransform={'uppercase'}
                fontSize={'xs'}
                letterSpacing={'tight'}
                color={'white'}
                ml={5}
                href="/work"
              >
                My Work
              </Link>
              <Link
                fontWeight={500}
                textTransform={'uppercase'}
                fontSize={'xs'}
                letterSpacing={'tight'}
                color={'white'}
                ml={5}
                href="https://cloudyside.com"
                isExternal
              >
                My Projects
              </Link>
            </Flex>
          </Flex>
        </Container>
      </Flex>

      <Spinner />
    </Flex>
  );
}

export default index;
