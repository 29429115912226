import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ChakraProvider, theme } from '@chakra-ui/react';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Work from './pages/Work';
import Navbar from './components/Navbar';
function App() {
  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/work" element={<Work />} />
      </Routes>
    </ChakraProvider>
  );
}
export default App;
