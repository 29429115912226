import React from 'react';
import { Flex, Text, Heading, Image, Link } from '@chakra-ui/react';
import Goldenwait from '../../assets/Goldenwait.png';
import Foundryco from '../../assets/Foundryco.png';
import Lootcrate from '../../assets/Lootcrate.png';
import Red from '../../assets/red.png';
import Cisco from '../../assets/linksys.png';
import BeyondMenu from '../../assets/beyondmenu.png';
import Broadcom from '../../assets/broadcomfoundation.png';
import CharmingCharlie from '../../assets/CharmingCharlie.png';
import Alpha from '../../assets/alpha.png';
import Faveable from '../../assets/faveable.png';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import Trees from '../../assets/trees.jpg';

const recentProjects = [
  {
    title: 'Golden Wait',
    role: 'Lead Developer | Co-founder',
    time: '2022 to Present',
    description:
      'Golden Wait is a web application for businesses to manage their  waitlists and grow with automated marketing tools',
    link: 'https://goldenwait.com',
    image: Goldenwait,
    flexDir: 'row',
    bg: 'gray.50',
    tech: 'React, Node, Firebase (Auth, Firestore), Chakra UI, OpenAI, Twilio, Google Cloud, Cloudflare (Functions, Pages, Workers)',
  },
  {
    title: 'Foundry / IDG',
    role: 'Lead Front-End Developer',
    time: '2022 to 2023',
    description:
      'My role was to work with a team of developers to build and maintain a suite of websites for the Foundry and IDG brands. I led front-end development in the transition of major media sites from an internal platform to Wordpress VIP.',
    link: 'https://foundryco.com',
    image: Foundryco,
    flexDir: 'row-reverse',
    sites:
      'MacWorld, PCWorld, TechHive, Network World, Computerworld, CIO, CSO, InfoWorld',
    tech: 'Wordpress VIP, PHP, Storybook, React, Google Tag Manager, Google Analytics',
    bg: 'gray.200',
  },
  {
    title: 'The Loot Company',
    role: 'Shopify Developer',
    time: '2020 to 2022',
    description:
      'As a front-end developer on this very high traffic subscription box site, I was responsible for building and maintaining the Loot Crate and Loot Vault Shopify sites. This includes the migration of subscription systems.',
    link: 'https://lootcrate.com',
    image: Lootcrate,
    flexDir: 'row',
    sites: 'Loot Crate, Loot Vault',
    tech: 'Shopify Plus, Liquid, React, JS, Bold Subscriptions, Recurly',
    bg: 'gray.50',
  },
  {
    title: 'Broadcom Foundation',
    role: 'Web Developer',
    time: '2018 to 2023',
    description:
      'During my time at the Broadcom Foundation, I was responsible for the development and maintenance of the foundation website. I also managed the development of the Broadcom MASTERS website.',
    link: 'https://broadcomfoundation.org',
    image: Broadcom,
    flexDir: 'row-reverse',
    tech: 'Wordpress, PHP, JS',
    bg: 'gray.200',
  },
  {
    title: 'Charming Charlie',
    role: 'Shopify Developer',
    time: '2020 to 2021',
    description:
      'I designed and built the Charming Charlie Shopify website for this large fashion retailer.',
    link: 'https://charmingcharlie.com',
    image: CharmingCharlie,
    flexDir: 'row-reverse',
    bg: 'gray.50',
    tech: 'Shopify Plus, Liquid',
  },
  {
    title: 'Beyond Menu',
    role: 'Wordpress Developer',
    time: '2020 to 2023',
    description:
      'I built and managed the Beyond Menu marketing website for this large food delivery service. I also led the development of a full site redesign.',
    link: 'https://beyondmenu.com',
    image: BeyondMenu,
    flexDir: 'row',
    tech: 'Wordpress, Elementor Pro, PHP, JS',
    bg: 'gray.200',
  },
  {
    title: 'Alpha Foods',
    role: 'Shopify Developer',
    time: '2020 to 2021',
    description:
      'I was responsible for front-end development for the Alpha Foods Shopify website. I also managed the development of the Alpha Foods subscription site.',
    link: 'https://eatalphafoods.com',
    image: Alpha,
    flexDir: 'row-reverse',
    bg: 'gray.50',
    tech: 'Shopify Plus, Liquid, JS, Bold Subscriptions',
  },
  {
    title: 'Faveable',
    role: 'Lead Developer | Co-founder',
    time: '2014 to 2020',
    description:
      'As a co-founder, I led development of the Faveable website, a product review and comparison site. With over 300k monthly users, I was responsible for the growth of the site through digital marketing and growth tools. We sold the site in 2020.',
    image: Faveable,
    flexDir: 'row-reverse',
    tech: 'LAMP, JS, Digital Marketing, growth tools',
    bg: 'gray.200',
  },
  {
    title: 'RED Digital Cinema',
    role: 'Director of Web & E-commerce',
    time: '2015 to 2018',
    description:
      'As the Director of Web & E-commerce, I led the team that was responsible for the development and management of RED websites.  Through these sites, we sold high-value RED cameras and accessories. I also managed the development of the RED Studios Hollywood website.',
    link: 'https://red.com',
    image: Red,
    flexDir: 'row-reverse',
    sites: 'RED, RED Studios Hollywood',
    tech: 'Ruby on Rails, custom e-commerce platform, Digital Marketing, growth tools',
    bg: 'gray.50',
  },
  {
    title: 'Cisco Systems',
    role: 'Interactive Development Manager',
    time: '2008 to 2014',
    description:
      "At the Cisco Consumer Business Group, I was responsible for the Web team that developed and managed the consumer side of Cisco's business the Linksys website. I also led the development of the Linksys E-commerce platform. Along with this, I managed consumer sites that were acquired by Cisco, including Pure Digital (Flip Video), and NetworkMagic.",
    link: 'https://linksys.com',
    image: Cisco,
    flexDir: 'row',
    sites: 'Linksys, Flip Video, NetworkMagic',
    tech: 'HTML, CSS, JS, Java, Google Analytics, Digital Marketing, E-commerce',
    bg: 'gray.200',
  },
];

function Index() {
  return (
    <>
      <Flex
        align={'center'}
        justify={'center'}
        bg={'black'}
        py={2}
        pos={'relative'}
      >
        <Heading
          color={'white'}
          fontSize={'4xl'}
          letterSpacing={'widest'}
          fontWeight={900}
          textTransform={'uppercase'}
        >
          My work
        </Heading>
      </Flex>
      {recentProjects.map((project, index) => {
        return (
          <Flex
            align={'center'}
            justify={'center'}
            // h={'50vh'}
            // flexDir={project.flexDir || 'row'}
            py={10}
            key={index}
            overflow={'hidden'}
            bg={project.bg}
            pos={'relative'}
          >
            <Flex w={'7xl'} flexDir={{ base: 'column', md: 'row' }}>
              <Flex flex={1} justify={'center'} p={5}>
                <Image src={project.image} boxShadow={'lg'} />
              </Flex>
              <Flex flexDir={'column'} flex={1} p={5} justify={'center'}>
                {project.link ? (
                  <Link
                    href={project.link}
                    fontSize={'4xl'}
                    fontWeight={700}
                    isExternal
                  >
                    {project.title}
                    <ExternalLinkIcon mx="2px" boxSize={5} ml={3} />
                  </Link>
                ) : (
                  <Heading fontSize={'4xl'} fontWeight={700}>
                    {project.title}
                  </Heading>
                )}
                <Text mb={2}></Text>
                <Heading fontSize={'xl'} mb={1} fontWeight={600}>
                  {project.role}
                </Heading>
                <Text mb={3} fontSize={'md'} fontWeight={600}>
                  {project.time}
                </Text>
                <Text mb={5} fontWeight={500}>
                  {project.description}
                </Text>
                {project.sites && (
                  <>
                    <Heading
                      fontSize={'md'}
                      fontWeight={800}
                      textTransform={'uppercase'}
                      mb={1}
                    >
                      Sites
                    </Heading>
                    <Text fontSize={'md'} mb={5}>
                      {project.sites}
                    </Text>
                  </>
                )}
                {project.tech && (
                  <>
                    <Heading
                      fontSize={'md'}
                      fontWeight={800}
                      textTransform={'uppercase'}
                      mb={1}
                    >
                      Tech stack
                    </Heading>
                    <Text>{project.tech}</Text>
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
        );
      })}
      <Flex
        align={'center'}
        justify={'center'}
        bg={'black'}
        h={'20vh'}
        flexDir={'column'}
        backgroundImage={`url(${Trees})`}
        backgroundSize={'cover'}
        backgroundPosition={'center'}
        backgroundRepeat={'no-repeat'}
      ></Flex>
    </>
  );
}

export default Index;
